import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { AuthService } from 'src/services/auth.service';
import { CustomersService } from 'src/services/customers.service';
import { MessageService } from 'src/services/message.service';

@Component({
  selector: 'app-edit-user-tag',
  templateUrl: './edit-user-tag.component.html',
  styleUrls: ['./edit-user-tag.component.scss'],
})
export class EditUserTagComponent implements OnInit {
  @ViewChild('nameInput', { static: false }) nameInput;

  isLoading = false;
  showAdvanced = false;
  name: string;
  id?: string;
  communityId: string;
  rule: any;

  constructor(
    navParams: NavParams,
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private customersService: CustomersService,
    public authService: AuthService,
  ) {
    this.id = navParams.get('id');
    this.communityId = navParams.get('communityId');
    this.showAdvanced = !!this.id;
  }

  ngOnInit(): void {
    if (this.id) {
      this.loadUserTag();
    } else {
      setTimeout(() => this.nameInput.setFocus(), 500);
    }
  }

  async loadUserTag() {
    this.isLoading = true;

    try {
      const userTag = await this.customersService.getUserTag(this.communityId, this.id);

      this.name = userTag.name;
      this.rule = userTag.rule;
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async submit() {
    if (!this.name) {
      await this.msgSrvc.show('Whoops! Please enter a name.');

      return;
    }

    this.isLoading = true;

    try {
      if (this.id) {
        await this.customersService.updateUserTag(this.communityId, this.id, this.name, this.rule);

        await this.msgSrvc.show('User tag updated!');
      } else {
        await this.customersService.createUserTag(this.communityId, this.name, this.rule);

        await this.msgSrvc.show('User tag added!');
      }

      this.dismiss();
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ruleDidChange(rule) {
    this.rule = rule;
  }
}
