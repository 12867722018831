import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { EditUserTagComponent } from './edit-user-tag.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [EditUserTagComponent],
  exports: [EditUserTagComponent],
})
export class EditUserTagModule {}
